

.MuiFormControl-root{
  margin-top: 2rem;
}
.MuiFormControl-root .loginBtn{
  background: #ffbc36;
}
.MuiFormControl-root .loginBtn:hover{
  background: #ffbc36;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.mb-3{
  margin-bottom: 1.375rem !important;
}
.d-block{
  display: block;
}
.text-success{
  color: green;
}

.lfFont {
  width: 260px;
  height: 120px;
  font-family: Heebo;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.lfFontB {
  font-weight: 500;
}

.mb-3{
  margin-bottom: 1.375rem !important;
}
