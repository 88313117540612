.--slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .--slide img, .val-- img {
    max-width: 100%;
  }
  
  .homepage-title {
    font-size: 1.4rem;
    padding-top: 2rem;
    padding-bottom: 1.8rem;
    line-height: 2rem;
    max-width: 850px;
    margin: auto !important;
    text-align: center;
  }
  
  .--header .action-btn-text, .--header .action-btn {
    margin: 0px 12px;
  }
  
  .homepage-subtitle {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  
  .val-cntr {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    align-items: flex-end
  }
  
  .val-- {
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 30px;
  }
  
  .val--:not(:last-child) {
    border-right: 1px solid #ececec;
    flex-grow: 1;
  }
  
  .val--:not(:last-child)::before {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    content: "To Enable my Child";
    font-weight: bold;
  }
  
  .val-- ul {
    text-align: left;
    padding: 0px 2rem;
  }
  
  .val-- ul {
    /* list-style: none; */
    font-size: 1.2rem;
  }
  
  
  
  .val-- ul li {
    margin: .5rem 0rem;
  }
  
  .val-- ul li:before {
    content: '✓';
    margin-right: 1rem;
    color: green;
    font-weight: bold;
  }
  
  .val-img-cntr {
    width: 60%;
    margin: auto;
    background-color: rgb(65, 171, 69);
    border-radius: 8px;
    height: 96px;
    display: flex;
    align-items: flex-end;
  }
  
  .action-btn-cntr {
    display: flex;
  }
  
  .policies-cntr a {
    line-height: 1.2;
    padding: 4px 8px;
    font-size: 0.75rem !important;
  }
  
  .policies-cntr a:not(:last-child) {
    border-right: 1px solid #ccc;
  }

  a {
      text-decoration: none;
  }



  body {
      overflow-x: hidden;
  }

  .productPage{
    display: flex;
    justify-content: space-between;
    margin: 0px 125px;
  }
  
  .left-col{
    max-width: 475px;
  }
  
  .right-col{
    min-width: 550px;
    padding: 0px 0px 0px 4.25rem;
    flex-grow: 1;
  }
  
  .ch-title{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
  }
  
  .ch-sub-title{
    font-size: 1.125rem;
    color: #686868;
  }
  
  .ch-description{
    margin: 0.5rem 0px;
    /* font-size: 0.825rem; */
    line-height: 1.5;
    color: #505050;
    font-size: 16px;
  }
  
  .productPage .recommendations{
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    margin: 1.5rem 0px;
    padding: 1rem 0px;
    display: flex;
    justify-content: space-between;
  }
  
  .productPage .recommendations span{
    display: block;
  }
  
  .productPage .-title{
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  
  ol.product-offering{
    padding-left: 1.2rem
  }
  
  ol.product-offering li{
    padding: 0.5rem
  }