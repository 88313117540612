.register_container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .register_wrapper {
        .MuiOutlinedInput-adornedEnd {
            padding-right: 0;
        }

        .verify_btn {
            width: 100%;
            height: 56px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #339519;
            color: #FFFFFF;

            &.Mui-disabled {
                background-color: #CCC;
            }
        }
    }

    .form_control {
        margin: 20px 0;
    }

    .submit_btn {
        background-color: #FFBC36;
        color: #000000;
        height: 50px;

        &:hover {
            background-color: #f5aa14;
        }
    }

    .f-links {
        font-size: 14px;
        text-align: center;
        padding: 30px 0 0 0;
    }
}