.d-block {
    display: block;
}

.font-12 {
    font-size: 12px;
}

.grey {
    color: #848484;
}

.mt-2 {
    margin-top: 1.2em;
}

.mt-1 {
    margin-top: 0.6em;
}

.graph-wrapper {
    text-align: center;
    margin-bottom: 30px;
}

.no-record-wrapper {
    text-align: center;
}

.no-record-wrapper > span {
    font-size: 18px;
}

