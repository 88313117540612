.auth_layout_container {
    width: 100%;
    height: 100%;

    .left_section {
        height: 100vh;
        padding: 100px 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #FBFAFB;

        .top {
            .top_text_wrapper {
                border-top: 4px solid #cacaca;
                border-bottom: 4px solid #cacaca;
                padding: 35px 0;
            }

            .head {
                font-size: 36px;
                font-weight: bold;
                line-height: 42px;
                margin-top: 10px;
            }

            .sub_head {
                font-size: 20px;
            }

            .university_info {
                padding: 39px 0;
                display: flex;
                align-items: center;

                .univ_logo_wrapper {
                    width: 54px;
                    height: 58px;

                    img {
                        width: 100%;
                    }
                }

                .univ_name {
                    padding: 0 15px;
                    font-size: 16px;
                }
            }
        }


    }

    .right_section {
        width: 400px;
        margin: 0 auto;
    }
}