.module-card {
  width: 260px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(64, 47, 13, 0.05);
  border: solid 1px #ebebeb;
  background-color: #ffffff;
  /* padding: 1.25rem 1.785rem; */
  padding: 0px;
  position: relative;
  overflow: hidden;
}

.-card-body {
  padding: 1.25rem 1.785rem;
}

.module-card:hover {
  box-shadow: 0 6px 10px 0 rgba(186, 31, 0, 0.1);
  border: solid 1px #ba1f00;
}

.status-icon {
  position: absolute;
  top: 1.25rem;
  right: 1.785rem
}

.cFoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cFoot .progress-root {
  width: 120px;
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.cFoot .progress-bar {
  border-radius: 5px;
}

/* .MuiDialog-paper{
  position: absolute !important;
  left: 37px;
  top: -20px;
  box-shadow: none !important;
  overflow:visible !important;
  background: #fff9c2 !important;
}
.MuiDialog-paper::before{
  content: '';
  position: absolute;
  display: block;    
  width: 0px;        
  left: 5px;
  top: 10%;
  border: 10px solid transparent;
  border-left: 0;
  border-right: 10px solid #fff9c2;
  transform: translate(calc(-100% - 5px), -50%);
} */