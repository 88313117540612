.drawer-paper {
  top: unset !important;
  width: 25%;
}

.drawer-docked {
  width: 25%;
  flex-shrink: 0;
}

.Collapsible__contentInner {
  margin-bottom: 1.2em;
}

.MuiDrawer-paperAnchorDockedLeft {
  overflow: scroll;
}

.Collapsible {
  padding: 1.5rem;
  background-color: #fcfcfc;
}

.chevron {
  color: #BA1F00;
  transition: transform 400ms !important;
  transition: color 400ms !important
}

.Collapsible__trigger.is-open .chevron {
  transform: rotate(180deg);
  color: #8c8c8c
}

.tool-cntr {
  display: flex;
  margin-top: 1.875rem
}

.tool-id {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: 0.28px;
  text-align: center;
}

.active .tool-id {
  background-color: #BA1F00;
  color: #FFF
}

.active-section {
  background-color: #fcfcfc;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.drawer-paper::-webkit-scrollbar {
  width: 6px;
}

.drawer-paper::-webkit-scrollbar-track {
  box-shadow: none;
}

.drawer-paper::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  outline: 1px solid slategrey;
  border-radius: 3px;
}