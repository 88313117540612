.auth-form-cntr {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg {
  font-size: 14px;
  margin: 30px 0px;
  height: 1.375em;
  color: #fb5d22;
}

.flexBox {
  display: flex;
}

.MuiFormControl-root {
  margin-top: 2rem;
}

.MuiFormControl-root .loginBtn {
  background: #ffbc36;
}

.MuiFormControl-root .loginBtn:hover {
  background: #ffbc36;
}

.text-right {
  text-align: right;
}

.d-block {
  display: block;
}

.my-3 {
  margin-top: 2.175rem !important;
  margin-bottom: 2.175rem !important;
}

.lfFont {
  width: 260px;
  height: 120px;
  font-family: Heebo;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.lfFontB {
  font-weight: 500;
}

.leftBoxLogin {
  /* width: 380px; */
  padding: 20px;
  background: #fafafa;
  float: left;
  padding-left: 77px;
  padding-top: 170px;
}

.rightBoxLogin {
  /* width: 986px; */
  padding: 20px;
  float: right;
  text-align: center;
  margin: 0px auto;
  padding-top: 170px;
}

.rightBoxLogin form {
  width: 386px;
  float: none;
  margin: 0px auto;
}

.flexBox.fbox {
  display: flex;
  width: 78%;
  height: 100%;
  margin: 0px auto;
}

.mb-3 {
  margin-bottom: 1.375rem !important;
}
