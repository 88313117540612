.fw-bold {
    font-weight: bold;
}

.d-grid {
    display: grid;
}

.gtc-4 {
    grid-template-columns: repeat(4, 1fr);
}

.bg-whitesmoke {
    background-color: #ECECEC;
}

.p-3 {
    padding: 3%;
}

.mt-20px {
    margin-top: 20px;
}

.mb-10px {
    margin-bottom: 10px;
}

.va-middle {
    vertical-align: middle;
}

.ai-center {
    align-items: center;
}

.ml-auto {
    margin-left: auto !important;
}

.br-30px {
    border-radius: 30px;
}

.br-15px {
    border-radius: 15px;
}

.orange {
    color: #F79D00 !important;
}

.ls-0-2 {
    letter-spacing: 0.2px;
}

.pointer {
    cursor: pointer;
}

.ml-5 {
    margin-left: 5%;
}

.ws-no-wrap {
    white-space: nowrap;
}

.mr-1-5rem {
    margin-right: 1.5rem;
}

.text-center {
    text-align: center;
}

.d-none {
    display: none;
}

.bg-white {
    background-color: white;
}

.bs-dashed {
    border-style: dashed;
}

.bs-dotted {
    border-style: dotted;
}

.bc-grey {
    border-color: #707070;
}

.gg-1 {
    grid-gap: 1%;
}

.w-30 {
    width: 30%;
}

.bs-solid {
    border-style: solid !important;
}

.p-3px {
    padding: 5px;
}

.mt_-1 {
    margin-top: -1% !important;
}

.bc-red {
    border-color: red !important;
}

.p-relative {
    position: relative;
}

.z-1 {
    z-index: 1;
}

.pb-2 {
    padding-bottom: 2% !important;
}

.bw-1px {
    border-width: 1px !important;
}

.w-fit-content {
    width: fit-content;
}

.red {
    color: red !important;
}

.p-0-5rem {
    padding: 0.5rem;
}

.m-0-5rem {
    margin: 0.5rem;
}

.br-12px {
    border-radius: 12px;
}

.margin-auto {
    margin: auto;
}

.w-22 {
    width: 22%;
}

.ap-batch-dashboard-delete-button {
    border: 2px solid red;
    border-top: none;
    text-align: center;
    width: 20%;
    border-radius: 0px 0px 14px 14px;
}

.font-weight-bold {
    font-weight: bold;
}