
.rightBoxReg form {
  width: 386px;
  float: none;
  margin: 0px auto;
}

.mbb-3{
  margin-bottom: 1.375rem !important;
}
.myy-3 {
  margin-top: 1rem !important;
  margin-bottom: 1.25rem !important;
}
.lfFontN {
  /* width: 310px;
  height: 120px; */
  font-family: Heebo;
  font-size: 20px;
  /* line-height: 1.8;
  letter-spacing: normal; */
  text-align: left;
  color: #000000;
}