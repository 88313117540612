.module-title {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.module-title a {
  display: flex;
  margin: 0px 30px;
}



.tool-card {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 2rem 2rem 0.5rem;
  max-width: 450px;
  background-color: #fff;
}


.mild-text {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
}

.module-heading {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  align-items: baseline;
}

.estd-time {
  font-size: small;
  font-weight: 400;
  opacity: 0.5;
}


.flex-column{
  flex-direction: column;
}

.left-card{
  width:250px;
  max-width:100%;
  border-radius:6px;
  padding:1rem;
}
.left-card ul{
  list-style:none;
  margin:0px;
  padding:0px;
  display:block;
  background: #ccc;
  border-radius: 6px;
}
.left-card ul li{
  display:block
}

.left-card ul li a{
  padding:7px 15px;
  display:block;
  text-decoration: none;
  color: #292929;
}

.right-card{
  width: calc(100vw - 480px);
  max-width:100%;
  border-radius:6px;
  padding:1rem;
}
.row{
  margin-left:-15px;
  margin-right:-15px;
}
.col{
  width:auto;
  max-width:100%;
  padding:0 15px;
}
.card{
  width:100%;
  max-width:100%;
  border-radius:6px;
  background:#ccc;
  margin-bottom: 15px;
}
.card-body{
  padding:1rem;
}
