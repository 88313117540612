* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-text .button-label {
  font-size: 0.8125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.26px;
  text-align: right;
  color: #ba1f00;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-inactive {
  opacity: 0.1;
}

html {
  scroll-behavior: smooth;
}

.h-30 {
  height: 30%;
}

.h-33 {
  height: 33%;
}