.auth-form-cntr {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg {
  height: 1.375em;
  color: #fb5d22;
}

.flexBox {
  display: flex;
}

.MuiFormControl-root {
  margin-top: 2rem;
}

.MuiFormControl-root .loginBtn {
  background: #ffbc36;
}

.MuiFormControl-root .loginBtn:hover {
  background: #ffbc36;
}

.text-right {
  text-align: right;
}

.mb-3 {
  margin-bottom: 1.375rem !important;
}

.d-block {
  display: block;
}

.lfFont {
  width: 260px;
  height: 120px;
  font-family: Heebo;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.lfFontB {
  font-weight: 500;
}


.rightBoxLogin form {
  width: 386px;
  float: none;
  margin: 0px auto;
}


.mb-3 {
  margin-bottom: 1.375rem !important;
}

.successDiv {
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.successText {
  text-align: center;
  margin-bottom: 25px;
  font-size: 28px;
}